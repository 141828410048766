import { render, staticRenderFns } from "./TargetGroupChannels.vue?vue&type=template&id=2d25e6f3&scoped=true&"
import script from "./TargetGroupChannels.vue?vue&type=script&lang=js&"
export * from "./TargetGroupChannels.vue?vue&type=script&lang=js&"
import style0 from "./TargetGroupChannels.vue?vue&type=style&index=0&lang=css&"
import style1 from "./TargetGroupChannels.vue?vue&type=style&index=1&id=2d25e6f3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d25e6f3",
  null
  
)

export default component.exports