<template>
    <div class="input-group">
        <v-select :clearable="false" :searchable="false" class="w-100" :options="fieldData.grouptime.options" v-model="fieldData.grouptime.tempGroupTime"> </v-select>
    </div>
</template>

<script>
import props from "@/mixins/inputProps"
import vSelect from "vue-select"
import dayjs from "dayjs"
import globalConfig from "../../../../mixins/globalConfig"

export default {
    name: "DropdownGroupTime",
    mixins: [props, globalConfig],
    components: {
        vSelect,
    },
    data() {
        return {}
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
        if (!this.config_EnableWeekAggChannel) {
            this.fieldData.grouptime.options = this.fieldData.grouptime.options.filter(obj => obj !== undefined && obj.id !== "week")
        }
    },
    watch: {
        "fieldData.grouptime.tempGroupTime"(groupTime) {
            let toDate = dayjs(this.fieldData.end_date.tempEndDate)
            if (groupTime.id === "sevendays") {
                let startDate = dayjs(this.fieldData.start_date.tempStartDate)
                let newEndDate = startDate.add(6, "d")
                if (newEndDate.isAfter(toDate)) {
                    newEndDate = toDate
                    let newStartDate = newEndDate.subtract(6, "d")
                    this.fieldData.start_date.tempStartDate = newStartDate.format("YYYY-MM-DD")
                }
                this.fieldData.end_date.tempEndDate = newEndDate.format("YYYY-MM-DD")
            } else if (groupTime.id === "week") {
                var weekday = require("dayjs/plugin/weekday")
                dayjs.extend(weekday)
                //if todate is not sunday set endate to previous sunday.
                if (toDate.get("d") !== 0) {
                    let newEndDate = toDate.weekday(-1)
                    this.fieldData.end_date.tempEndDate = newEndDate.format("YYYY-MM-DD")
                }
                //else set channelsenddate to todate
                else this.fieldData.end_date.tempEndDate = toDate.format("YYYY-MM-DD")

                let newChannelStartDate = ""
                //if todate is sunday set startdate to this weeks monday
                if (dayjs(toDate).get("d") === 0)
                    newChannelStartDate = dayjs(toDate)
                        .weekday(0)
                        .format("YYYY-MM-DD")
                //else set to previous monday
                else {
                    newChannelStartDate = dayjs(toDate)
                        .weekday(-7)
                        .format("YYYY-MM-DD")
                }
                this.fieldData.start_date.tempStartDate = newChannelStartDate
            }
            this.fieldData.end_date.disabled = groupTime.id === "sevendays" ? true : false
        },
    },
}
</script>

<style scoped>
.input-group {
    font-size: 0.875rem;
}
>>> .vs__selected-options {
    height: 1.6rem;
}
</style>
