<template>
    <div>
        <date-picker
            v-model="fieldData.start_date.tempStartDate"
            :months="months"
            :weekdays="weekdays"
            :class="[fieldData.start_date.hasError ? 'errorinput' : '', 'datepicker']"
            :show-week-number="true"
            valueType="format"
            :disabled-date="isDateDisabled"
            :clearable="false"
        ></date-picker>
        <label class="text-danger" v-if="fieldData.start_date.errorMsg.length > 0">{{ fieldData.start_date.errorMsg }}</label>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import "vue2-datepicker/locale/sv"
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
import propsDate from "@/mixins/inputPropsDate"
import datePickerConfig from "@/mixins/datePickerConfig"
import { isDateNotBetweenTwoDates, dateToyymmddFormat } from "../../../../api/utils.js"
import dayjs from "dayjs"
import globalConfig from "../../../../mixins/globalConfig"
export default {
    name: "DateFromChannels",
    components: {
        DatePicker,
    },
    mixins: [computedVal, props, propsDate, globalConfig, datePickerConfig],
    computed: {
        isDateValidFormat() {
            return dayjs(this.fieldData.start_date.tempStartDate, "YYYY-MM-DD").format("YYYY-MM-DD") === this.fieldData.start_date.tempStartDate
        },
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
        require("dayjs/locale/sv")
        dayjs.locale("sv")
        if (this.fieldData.start_date.tempStartDate) this.checkIfError(this.fieldData.start_date.tempStartDate)
    },
    methods: {
        isDateDisabled(datepickerDate) {
            let fromDate = new Date(this.$store.getters.getPublishedFromDate)
            let toDate = new Date(this.$store.getters.getPublishedToDate)
            let disableDate = false
            let selectedGroupTimeId = this.fieldData.grouptime.tempGroupTime.id
            if (selectedGroupTimeId === "sevendays") {
                var isBetween = require("dayjs/plugin/isBetween")
                dayjs.extend(isBetween)
                let sevenDaysBeforeLastToDate = dayjs(toDate).subtract(6, "d")
                let dateIsLessThanSevenDaysFromToDate = dayjs(dateToyymmddFormat(datepickerDate)).isBetween(sevenDaysBeforeLastToDate, toDate, "d")
                if (dateIsLessThanSevenDaysFromToDate || dayjs(dateToyymmddFormat(datepickerDate)).isSame(toDate, "d") || isDateNotBetweenTwoDates(datepickerDate, fromDate, toDate)) disableDate = true
            } else if (selectedGroupTimeId === "week") {
                let datepickerDateDayjsObj = dayjs(dateToyymmddFormat(datepickerDate))
                //if datepickerDate is not monday or datepickerdate is not between todate and enddate disable date
                if (datepickerDateDayjsObj.get("d") !== 1 || isDateNotBetweenTwoDates(datepickerDate, fromDate, toDate)) disableDate = true
            } else {
                if (isDateNotBetweenTwoDates(datepickerDate, fromDate, toDate)) disableDate = true
            }
            return disableDate
        },
        checkIfError(chosendate) {
            this.fieldData.hasError = false
            this.fieldData.errorMsg = ""
            if ((this.isDateDisabled(new Date(chosendate)) && this.fieldData.required) || !this.isDateValidFormat) {
                this.fieldData.hasError = true
                this.fieldData.errorMsg = "Det saknas tittardata för valt datum eller så är datumet i fel format"
            }
        },
    },
    watch: {
        "fieldData.tempStartDate"(chosendate) {
            this.checkIfError(chosendate)
        },
    },
}
</script>
<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}
</style>
